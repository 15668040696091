import {Button, Flex, Image, Spinner, Stack, Text, Tooltip, useToast} from "@chakra-ui/react";
import {Link, useNavigate} from "react-router-dom";
import {useWorkspace} from "../../context/WorkspaceContext";
import React, {useEffect, useState} from "react";
import {useUser} from "../../context/UserContext";
import {BraincapTask, PodcastTask, ServiceType} from "../../models/Localization";
import {Labeler} from "../../models/Labeler";
import {secondsToHHMMSS} from "../../utils/text-utils";
import {apiStates, ENDPOINTS, useApi} from "../../api/braincap-api";

export const TaskCard = ({task}: {task: BraincapTask}) => {
    const user: any = useUser()
    const workspace: any = useWorkspace()
    const navigate = useNavigate();
    const [isPartOfTeam, setIsPartOfTeam] = useState<boolean>(false)
    const [isDisabled, setIsDisabled] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const startTaskReq = useApi(ENDPOINTS.START_TRANSCRIPTION_SESSION.concat(task.id), user.token, false)
    const toast = useToast()
    const result = task.episode.title.search('SERIES');
    if (result !== -1) {
        console.log(`Task ${task.id} is a guest series task with title ${task.episode.title}`)
    }

    useEffect(() => {
        if (!user.loading && !workspace.jobsLoading && task) {
            setIsPartOfTeam(user.user.ongoing_sessions.includes(task.id as string))
            let ongoingCheck = user.user.ongoing_sessions.length > 0 && !user.user.ongoing_sessions.includes(task.id as string)
            //let assignedCheck = user.user.assigned_sessions.length > 0 && !user.user.assigned_sessions.includes(task.id as string)
            setIsDisabled(false)
        }
    }, [user.loading, workspace.jobsLoading])

    useEffect(() => {
        if (!startTaskReq.loading && startTaskReq.state === apiStates.SUCCESS) {
            let currentUser = user.user
            currentUser.ongoing_sessions = [...currentUser.ongoing_sessions, task.id]
            user.setUser(currentUser)
            workspace.setTask(task)
            navigate(`/editor/${task.id}/${task.podcast.id}/${task.episode.id}`)
            setLoading(false)
        } else if (!startTaskReq.loading && startTaskReq.error) {
            setLoading(false)
            toast({
                title: 'Error',
                description: 'Could not start task. Please try again or contact support.',
                status: 'error',
                duration: 3000,
                isClosable: true,
                position: 'top'
            })
        }
    }, [startTaskReq.loading])


    let image_url = "https://megaphone.imgix.net/podcasts/042e6144-725e-11ec-a75d-c38f702aecad/image/Huberman-Lab-Podcast-Thumbnail-3000x3000.png?ixlib=rails-4.3.1&max-w=3000&max-h=3000&fit=crop&auto=format,compress"
    return (
        <Flex w={'fit-content'} p="12px" flexDirection="column" alignItems="center" justifyContent={'flex-start'} bgColor={'#fff'} borderRadius="12px" gap={'8px'} boxShadow={'sm'}>
            <Flex>
                <Image fallbackSrc={image_url} src={`${task.episode.image_url}`} boxSize={'220px'} borderRadius="8px" bgColor="gray.100"/>
            </Flex>
            <Flex wordBreak={'break-word'} overflow='hidden' textOverflow='ellipsis' w={'full'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <Text noOfLines={1} w={'220px'} color="gray.700" fontSize="16px" fontWeight="500" lineHeight="24px">{task.episode.title}</Text>
            </Flex>
            <Flex w={'full'} justifyContent={'space-between'} flexDirection={'row'} alignItems={'center'}>
                <Text fontWeight={500} fontSize={'16px'} fontFamily={'sans-serif'}  color={'gray.600'}>
                    {`$${task.price} USD`}
                </Text>
                <Text color="gray.500" fontSize="14px" fontWeight="400" lineHeight="20px">{`${secondsToHHMMSS(Math.round(task.episode.duration/60))}`}</Text>
            </Flex>
            {!isPartOfTeam?
                <Button isDisabled={loading || isDisabled} onClick={() => {
                    setLoading(true)
                    startTaskReq.setPayloadData({labeler: user.user, podcast: task.podcast, session: task})
                }} w={'full'} variant={'solid'}>
                    {loading? <Spinner/> : 'Transcribe'}
                </Button>
                :
                <Button onClick={() => {
                    workspace.setTask(task)
                    navigate(`/editor/${task.id}/${task.podcast.id}/${task.episode.id}`)
                }} w={'full'} variant={'outline'}>
                    Resume
                </Button>
            }
        </Flex>
    )
}

const getNavigationPath = (type: ServiceType): string => {
    let path: string
    switch (type) {
        case ServiceType.TRANSCRIPTION:
            path = "/tasks/transcription"
            break


        case ServiceType.TRANSLATION:
            path = "/tasks/translation"
            break


        case ServiceType.DUBBING:
            path = "/tasks/dubbing"
            break

        default:
            path = 'uncategorized_labeler'
    }

    return path
}

export const getButtonText = (task: BraincapTask, labeler: Labeler): string => {
    let text: string
    switch (task.type) {
        case ServiceType.TRANSCRIPTION:
            text = "Transcribe"
            break

        case ServiceType.TRANSLATION:
            if (task.labeler?.id === labeler.id) {
                text = "Continue"
                break
            } else {
                text = "Translate"
            }
            break

        case ServiceType.DUBBING:
            text = "Dub"
            break


        default:
            text = 'uncategorized_labeler'
    }

    return text
}
