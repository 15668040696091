import {createContext, useContext, useEffect, useState} from "react";
import LoadingSpinner from "../components/loading-spinner";
import {ENDPOINTS, useApi} from "../api/braincap-api";
import React from "react";
import {StorageUtils} from "../utils/storage-utils";

export const AuthContext = createContext(null);

export default function AuthProvider(props: any) {
    const [userStale, setUserStale] = useState(true);
    const [loading, setLoading] = useState(true);
    const [localToken, setLocalToken] = useState<any>(null)
    const [token, setToken] = useState<any>(null)
    const [login, setLogin] = useState(false)
    const [openDrawer, setOpenDrawer] = useState(false)

    const userVerificationReq = useApi(ENDPOINTS.USER_VERIFY, localToken, false)

    useEffect(() => {
        if (userStale) {
            StorageUtils.getLocaleStorageToken().then(val => {
                if (val) {
                    setLocalToken(val)
                } else {
                    setLoading(false)
                    setUserStale(false)
                }
            })
        }

    }, [userStale])

    useEffect(() => {
        if (!!localToken) {
            userVerificationReq.execute()
        }
    }, [localToken])

    useEffect(() => {
        if (!userVerificationReq.loading && userVerificationReq.data) {
            setToken(userVerificationReq.data.token)
            setLoading(false)
        }

        if (!userVerificationReq.loading && userVerificationReq.error) {
            console.log(`AUTH_CONTEXT -> Unable to identify token: ${JSON.stringify(localToken)}`)
            StorageUtils.deleteLocaleStorageToken()
            window.location.href = "/"
            setLoading(false)
        }

        setUserStale(false)
    }, [userVerificationReq.loading])

    if (loading) {
        return <LoadingSpinner/>
    }

    const logout = () => {
        StorageUtils.deleteLocaleStorageToken()
        window.location.href = "/"
    }

    return (
        <AuthContext.Provider value = {{
            token: token,
            loading: loading,
            openDrawer: openDrawer,
            setOpenDrawer: setOpenDrawer,
            logout: () => {
                logout()
            }
        }} {...props} />
    )
}

export const useAuth = () => useContext(AuthContext)



