import {createContext, useContext, useEffect, useState} from "react";
import {useUser} from "./UserContext";
import {ENDPOINTS, useApi} from "../api/braincap-api";
import React from "react";
import {Podcast} from "../models/Podcast";
import {BraincapTask} from "../models/Localization";

export const WorkspaceContext = createContext(null);

export default function WorkspaceProvider(props: any) {
    const user: any = useUser()
    const userId = user.userId
    const token = user.token
    const [labeler, setLabeler] = useState<any>()
    const [jobs, setJobs] = useState<Podcast[]>()
    const [tasks, setTasks] = useState<BraincapTask[]>([]);
    const [tasksMap, setTasksMap] = useState<Map<string, BraincapTask>>(new Map())
    const [task, setTask] = useState<BraincapTask>()
    const [jobsLoading, setJobsLoading] = useState(false);
    const [tasksLoading, setTasksLoading] = useState(false);
    const [tasksDownloaded, setTasksDownloaded] = useState(false);
    const [jobsDownloaded, setJobsDownloaded] = useState(false);
    const [endpoint, setEndpoint] = useState<any>('')
    const [navBarEpisodeTitle, setNavBarEpisodeTitle] = useState<string>('')
    const [overrideNavBarEpisodeTitle, setOverrideNavBarEpisodeTitle] = useState<boolean>(false)

    const teamsReq = useApi(ENDPOINTS.GET_TEAMS, token, false)
    const taskReq = useApi(endpoint, token, false)

    useEffect(() => {
        if (!!userId && !jobsDownloaded) {
            setJobsLoading(true)
            teamsReq.execute()
        }
    }, [userId])

    useEffect(() => {
        if (!teamsReq.loading && teamsReq.data) {
            setJobs(teamsReq.data)
            setJobsLoading(false)
            setJobsDownloaded(true)
        }
    }, [teamsReq.loading])

    useEffect(() => {
        if (tasks.length > 0 && tasksDownloaded) {
            let taskMap = new Map()
            tasks.forEach((task: BraincapTask) => {
                taskMap.set(task.id, task)
            })
            setTasksMap(taskMap)
        }
    }, [tasks, tasksDownloaded])


    return (
        <WorkspaceContext.Provider value = {{
            jobs: jobs,
            jobsLoading: jobsLoading,
            tasks: tasks,
            setTasks: setTasks,
            tasksMap: tasksMap,
            jobsDownloaded: jobsDownloaded,
            tasksDownloaded: tasksDownloaded,
            navBarEpisodeTitle: navBarEpisodeTitle,
            overrideNavBarEpisodeTitle: overrideNavBarEpisodeTitle,
            setOverrideNavBarEpisodeTitle: setOverrideNavBarEpisodeTitle,
            setTaskDownloaded: setTasksDownloaded,
            setTask: setTask,
            setNavBarEpisodeTitle: setNavBarEpisodeTitle
        }} {...props} />
    )
}

export const useWorkspace = () => useContext(WorkspaceContext)


