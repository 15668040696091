import {Button, Flex, Grid, SimpleGrid, Skeleton, Text, useDisclosure} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {SearchLogo} from "../../assets/SearchIcon";
import {AddIcon} from "@chakra-ui/icons";
import {ShowCardSkeleton} from "../../components/skeletons";
import {useAuth} from "../../context/AuthContext";
import {useUser} from "../../context/UserContext";
import {Podcast} from "../../models/Podcast";
import {ShowCard} from "../../components/show/show-card";
import {useWorkspace} from "../../context/WorkspaceContext";
export default function JobBoard() {
    const auth: any = useAuth()
    const user: any = useUser()
    const workspace: any = useWorkspace()
    const [podcasts, setPodcasts] = useState<Podcast[]>([])
    const [hasUploadedContent, setHasUploadedContent] = useState(false)
    const [searchTerm, setSearchTerm] = useState('');

    const { isOpen, onOpen, onClose } = useDisclosure()

    useEffect(() => {
        if (!workspace.jobsLoading && workspace.jobs) {
            const podcastList: Podcast[] = []
            workspace.jobs.forEach((podcast: Podcast) => {
                podcastList.push(podcast)
            })
            setPodcasts(podcastList)
        }
    }, [workspace.jobsLoading])

    const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const filteredPodcasts = podcasts.filter((podcast) => {
        const { title } = podcast;
        const normalizedSearchTerm = searchTerm.toLowerCase();
        return (
            title.toLowerCase().includes(normalizedSearchTerm)
        );
    });

    return (
        <Flex w="full" flexDirection="column" pt={'112px'} alignItems={'flex-start'} gap={'32px'}>
            <Flex w="full" flexDirection="row" alignItems={'center'} justifyContent={'space-between'}>
                <SearchLogo onType={handleSearchInputChange} onOpenWidth={'20%'}/>
                <></>
            </Flex>
            <Flex w="full" flexDirection="column" alignItems={'flex-start'} gap={'24px'}>
                {!workspace.jobsLoading && workspace ?
                    <Grid w={'full'} templateColumns='repeat(4, 1fr)' gap={'8px'}>
                        {filteredPodcasts.map((podcast: Podcast) => (
                            <ShowCard key={`${podcast.id}`} podcast={podcast}/>
                        ))}
                    </Grid>
                    :
                    <Grid w={'full'} templateColumns='repeat(4, 1fr)' gap={'24px'}>
                        {[...Array(2)].map((_, index) => (
                            <ShowCardSkeleton key={index}/>
                        ))}
                    </Grid>
                }
            </Flex>
        </Flex>
    )
}

