import {Flex, Icon} from "@chakra-ui/react";

export const EditTranscriptLogo = () => {
    return (
        <Flex>
            <Icon width="50" height="58" viewBox="0 0 50 58" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M49.3465 14.3651C49.2117 14.0953 49.0768 13.893 48.8744 13.6907L34.914 0.539535C34.8465 0.472093 34.7791 0.404651 34.6442 0.337209H34.5768C34.5093 0.269767 34.4419 0.269768 34.307 0.202326C34.2396 0.202326 34.2396 0.202326 34.1721 0.134884C34.1047 0.134884 33.9698 0.0674421 33.9023 0.0674421H33.8349C33.7 0.0674421 33.5651 0 33.4977 0H2.47443C1.32792 0 0.451172 0.876744 0.451172 2.02326V11.1279C0.451172 12.2744 1.32792 13.1512 2.47443 13.1512C3.62094 13.1512 4.49768 12.2744 4.49768 11.1279V4.04651H31.5419V15.107C31.5419 16.2535 32.4186 17.1302 33.5651 17.1302H45.5023V53.9535H4.49768V19.0186C4.49768 17.8721 3.62094 16.9953 2.47443 16.9953C1.32792 16.9953 0.451172 17.8721 0.451172 19.0186V55.9767C0.451172 57.1233 1.32792 58 2.47443 58H47.5256C48.6721 58 49.5489 57.1233 49.5489 55.9767V15.1744C49.4814 14.8372 49.414 14.5674 49.3465 14.3651ZM35.521 13.1512V6.74419L42.3326 13.1512H35.521Z" fill="#319795"/>
                <path d="M14.614 30.4837C15.0186 30.8883 15.5582 31.0906 16.0302 31.0906C16.5023 31.0906 17.0419 30.8883 17.4465 30.4837L26.4163 21.5813C27.2256 20.772 27.2256 19.4906 26.4163 18.7488C25.607 17.9395 24.3256 17.9395 23.5837 18.7488L16.0302 26.1674L12.9954 23.1325C12.1861 22.3232 10.9047 22.3232 10.1628 23.1325C9.3535 23.9418 9.3535 25.2232 10.1628 25.965L14.614 30.4837Z" fill="#319795"/>
                <path d="M10.1628 44.1071L14.6814 48.6257C15.0861 49.0304 15.6256 49.2327 16.0977 49.2327C16.6372 49.2327 17.1093 49.0304 17.514 48.6257L26.4163 39.656C27.2256 38.8467 27.2256 37.5653 26.4163 36.8234C25.607 36.0815 24.3256 36.0141 23.5837 36.8234L16.0302 44.242L12.9954 41.2071C12.1861 40.3978 10.9047 40.3978 10.1628 41.2071C9.3535 42.0164 9.3535 43.2978 10.1628 44.1071Z" fill="#319795"/>
                <path d="M29.3162 26.6395H38.3534C39.4999 26.6395 40.3766 25.7628 40.3766 24.6163C40.3766 23.4698 39.4999 22.593 38.3534 22.593H29.3162C28.1697 22.593 27.2929 23.4698 27.2929 24.6163C27.2929 25.7628 28.1697 26.6395 29.3162 26.6395Z" fill="#319795"/>
                <path d="M29.3162 44.7138H38.3534C39.4999 44.7138 40.3766 43.8371 40.3766 42.6906C40.3766 41.5441 39.4999 40.6673 38.3534 40.6673H29.3162C28.1697 40.6673 27.2929 41.5441 27.2929 42.6906C27.2929 43.8371 28.1697 44.7138 29.3162 44.7138Z" fill="#319795"/>
            </Icon>
        </Flex>
    )
}
