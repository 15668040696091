import {Link, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import "plyr-react/plyr.css"
import {Box, Button, Center, Divider, Flex, Slide, Text} from "@chakra-ui/react";

import {ChevronLeftIcon} from "@chakra-ui/icons";
import {useWorkspace} from "../../context/WorkspaceContext";
import {TranscriptReader} from "./transcript-reader";
import {TranscriptPage} from "./transcript-page";

export default function TranscriptEditor() {
    const [loading, setLoading] = useState<boolean>(true);
    const workspace: any = useWorkspace()

    return (
        <Flex w={'full'} direction={'column'} gap={'16px'} alignItems={'flex-start'}>
            <TranscriptPage />
        </Flex>
    );
};
