import {Flex, Icon} from "@chakra-ui/react";

export const SearchLogo = () => {
    return (
        <Flex>
            <Icon width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M56.7454 51.7174L41.1805 38.2994C40.291 39.3657 39.3127 40.3432 38.2454 41.2318L51.6757 56.7825C51.7646 56.8714 51.9425 57.0491 52.0315 57.138C53.5435 58.382 55.7671 58.2932 57.1012 56.7825C58.4353 55.2719 58.2575 52.9615 56.7454 51.7174Z" fill="#319795"/>
                <path d="M44.4713 22.2154C44.4713 9.95248 34.5098 0 22.2357 0C9.96158 0 0 9.95248 0 22.2154C0 34.4782 9.96158 44.4307 22.2357 44.4307C27.6612 44.4307 32.7309 42.4758 36.5554 39.1879C37.5338 38.3881 38.4232 37.4995 39.2237 36.522C42.5146 32.701 44.4713 27.6359 44.4713 22.2154ZM22.2357 39.9876C12.452 39.9876 4.44713 31.9901 4.44713 22.2154C4.44713 12.4406 12.452 4.44307 22.2357 4.44307C32.0194 4.44307 40.0242 12.4406 40.0242 22.2154C40.0242 31.9901 32.0194 39.9876 22.2357 39.9876Z" fill="#319795"/>
            </Icon>
        </Flex>
    )
}
