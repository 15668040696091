import React, { Ref, PropsWithChildren } from 'react'
import {Button, Flex, Portal} from "@chakra-ui/react";

export interface BaseProps {
    className: string
    [key: string]: unknown
}
type OrNull<T> = T | null

export const ToolbarButton = React.forwardRef(
    (
        {
            active,
            reversed,
            children,
            ...props
        }: PropsWithChildren<
            {
                active: boolean
                reversed: boolean
            } & BaseProps
        >,
        ref: any
    ) => (
        <Button
            ref={ref}
            px={'8px'}
            variant="unstyled"
            {...props}
        >
            <Flex alignItems="center" justifyContent="center" direction={'row'} gap={'6px'}>
                {children}
            </Flex>
        </Button>
    )
);

export const ToolbarEditBox = React.forwardRef(
    (
        {
            children,
            ...props
        }: PropsWithChildren<BaseProps>,
        ref: any
    ) => (
        <Flex
            ref={ref}
            direction={'column'}
            w={'full'}
            justifyContent={'center'}
            alignItems={'flex-start'}
            {...props}
        >
            {children}
        </Flex>
    )
);
export const ToolbarMenu = React.forwardRef(
    ({ children, ...props }: PropsWithChildren<BaseProps>, ref: any) => (
        <Flex
            ref={ref}
            direction={'row'}
            alignItems={'center'}
            justifyContent={'center'}
            position="absolute"
            zIndex="1"
            top="-10000px"
            left="-10000px"
            mt="-6px"
            opacity="0"
            backgroundColor="white"
            borderRadius="8px"
            border={'1px solid'}
            borderColor={'gray.300'}
            transition="opacity 0.75s"
            {...props}
        >
            {children}
        </Flex>
    )
);


export const ToolbarPortal = React.forwardRef(({ children, ...props }: PropsWithChildren<BaseProps>, ref: any) => (
        <Portal {...props}>{children}</Portal>
    )
)
