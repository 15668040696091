import {Flex, Icon, Input} from "@chakra-ui/react";
import React, {useState} from "react";
export const SearchLogo = ({onType, onOpenWidth}: {onType: (event: React.ChangeEvent<HTMLInputElement>) => void, onOpenWidth: any }) => {
    const [isSearchOpen, setIsSearchOpen] = useState<boolean>(false);
    const [searchTerm, setSearchTerm] = useState('');

    return (
        <Flex w={isSearchOpen ? onOpenWidth : '40px'} h={'40px'} direction={'row'} border={'1px'} borderColor={'gray.200'} borderRadius={'100px'} p={'11px'} gap={'10px'} alignItems={'center'} bgColor={'white'}>
            <Icon onClick={() => {isSearchOpen ? setIsSearchOpen(false) : setIsSearchOpen(true)}} width="14px" height="14px" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.6642 11.8358L10.0975 9.275C10.7382 8.46021 11.0855 7.4532 11.0833 6.41667C11.0833 5.49369 10.8096 4.59144 10.2969 3.82401C9.78408 3.05658 9.05525 2.45844 8.20252 2.10523C7.3498 1.75202 6.41149 1.65961 5.50625 1.83967C4.601 2.01974 3.76948 2.46419 3.11684 3.11684C2.46419 3.76948 2.01974 4.601 1.83967 5.50625C1.65961 6.41149 1.75202 7.3498 2.10523 8.20252C2.45844 9.05525 3.05658 9.78408 3.82401 10.2969C4.59144 10.8096 5.49369 11.0833 6.41667 11.0833C7.4532 11.0855 8.46021 10.7382 9.275 10.0975L11.8358 12.6642C11.9457 12.774 12.0947 12.8357 12.25 12.8357C12.4053 12.8357 12.5543 12.774 12.6642 12.6642C12.774 12.5543 12.8357 12.4053 12.8357 12.25C12.8357 12.0947 12.774 11.9457 12.6642 11.8358ZM3.94334 8.91917C3.364 8.35265 2.99917 7.60266 2.91111 6.79717C2.82304 5.99167 3.01718 5.18058 3.46042 4.50225C3.90365 3.82393 4.56851 3.32041 5.34157 3.0776C6.11463 2.83479 6.94799 2.86773 7.69947 3.17081C8.45095 3.47388 9.074 4.02831 9.46231 4.7395C9.85061 5.45069 9.98013 6.27459 9.82876 7.07062C9.67738 7.86665 9.2545 8.58549 8.63225 9.10452C8.01 9.62354 7.22694 9.91058 6.41667 9.91667C5.48872 9.91585 4.59908 9.54657 3.94334 8.89V8.91917Z" fill="#2D3748"/>
            </Icon>
            {isSearchOpen && <Input onChange={onType} p={'0px'} fontWeight={400} fontSize={'14px'} variant={'unstyled'} fontFamily={'sans-serif'} fontStyle={'italic'} placeholder={'Type here'}/>}
        </Flex>
    )
}
