import {useAuth} from "./context/AuthContext";
import {Route, Routes} from "react-router-dom";
import Signup from "./pages/auth/Signup";
import JobBoard from "./pages/teams/JobBoard";
import Login from "./pages/auth/Login";
import LabelerLayout from "./pages/labeler-layout";
import Dashboard from "./pages/dahsboard/Dahsboard";
import TranscriptLayout from "./components/transcriptionist/transcript-layout";
import TranscriptEditor from "./components/transcriptionist/transcript-editor";

export const PAGE = {
    HOME: 'Podcasts',
    PODCAST: 'Podcast',
    LIBRARY: 'Library',
    EPISODES: 'Episodes',
    CONFIGURATION: "Configuration",
    TASKS: "TASKS",
    TRANSCRIPTION: "Transcription",
    TRANSLATION: "Translation",
    MAKTUB: "Maktub",
    ACCOUNT: "Account",
    JOBS: "Jobs",
    DASHBOARD: "Dashboard",
    EDITOR: "Editor"
};

export default function App() {
    const auth: any = useAuth()
    const isLoggedIn = !!auth.token
    console.log(`Are we loggedIn: ${!!auth.token}`)

    return (
        <Routes>
            {/* Entry Routes*/}
            <Route path="/" element={isLoggedIn? <LabelerLayout name={PAGE.JOBS} children={<JobBoard/>}/> : <Login/>} />
            <Route path="/signup" element={<Signup/>} />
            <Route path="/dashboard" element={isLoggedIn? <LabelerLayout name={PAGE.DASHBOARD} children={<Dashboard/>}/> : <Login/>} />
            <Route path="/editor/:sessionId/:podcastId/:episodeId" element={isLoggedIn? <LabelerLayout name={PAGE.DASHBOARD} children={<TranscriptEditor/>}/> : <Login/>} />
        </Routes>
    );
}
