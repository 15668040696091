import {CircularProgressbar, buildStyles} from "react-circular-progressbar";
import 'react-circular-progressbar/dist/styles.css';
import {
    Flex,
    Skeleton,
    Text,
    Image,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    useToast,
    Slide,
    Box, Center, Button, FormControl, Checkbox, FormErrorMessage
} from "@chakra-ui/react";
import {EpisodesIcon} from "../../assets/EpisodesIcon";
import React, {useEffect, useState} from "react";
import {Podcast} from "../../models/Podcast";
import {removePTags} from "../../utils/text-utils";
import {ENDPOINTS, useApi} from "../../api/braincap-api";
import {Speaker} from "../../models/Speakers";
import {useUser} from "../../context/UserContext";
import {Link} from "react-router-dom";
import {SearchLogo} from "../../assets/SearchLogo";
import {EditTranscriptLogo} from "../../assets/EditTranscriptLogo";
export default function OverviewModule({podcast}: {podcast: Podcast}) {
    const user: any = useUser()
    const [tabIndex, setTabIndex] = useState<number>(0)
    const [episodeLoading, setEpisodeLoading] = useState<boolean>(true)
    const [hasEpisodes, setHasEpisodes] = useState<boolean>(false)
    const [languagesProgress, setLanguagesProgress] = useState<any>({})
    const [episodeTranscriptionProgress, setEpisodeTranscriptionProgress] = useState<any>({})
    const [currentLanguage, setCurrentLanguage] = useState<string>()
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [termsError, setTermsError] = useState(false);
    const toast: any = useToast()

    const styles = buildStyles({
        pathColor: '#38B2AC'
    })

    const handleLanguageChange = () => {
        if (Object.keys(languagesProgress).length === 0) {
            toast({
                title: "No languages available",
                description: "You have not yet localized an episode for this podcast",
                status: "warning",
                duration: 3000,
            })
        }
    }

    const ObjectivesComponent = ({icon, description}: {icon: any, description: any}) => {
        return (
            <Flex w={'full'} h={'full'} p={'12px'} justifyContent={'flex-start'}>
                <Flex w={'full'} gap={'12px'} direction={'column'} justifyContent={'center'} alignItems={'center'}>
                    {icon}
                    <Text align={'start'} color="gray.500" fontSize="12px" fontStyle="normal" fontWeight="400" lineHeight="20px">
                        {description}
                    </Text>
                </Flex>
            </Flex>
        )
    }

    return (
        <Flex direction={'column'} alignItems={'flex-start'} gap="24px" alignSelf={'stretch'}>
            <Flex p="16px" flexDirection="column" alignItems="flex-start" gap="12px" flex="1 0 0" borderRadius="12px" border="1px" borderColor="gray.300">
                <Flex alignItems={'flex-start'} gap="16px" alignSelf={'stretch'}>
                    <Image src={podcast.image_url} boxSize={'64px'} borderRadius="12px" />
                    <Flex direction={'column'} alignItems={'flex-start'} gap={'8px'} flex={'1 0 0'}>
                        <Text color="gray.800" textAlign="center" isTruncated fontSize="16px" fontStyle="normal" fontWeight="600" lineHeight="24px">
                            {podcast.title}
                        </Text>
                        <Flex direction={'row'} alignItems={'center'} gap={'8px'} alignSelf={'stretch'}>
                            <EpisodesIcon/>
                            <Text color="gray.700" fontSize="14px" fontStyle="normal" fontWeight="400" lineHeight="20px">
                                {podcast.episodes.length} Episodes
                            </Text>
                        </Flex>
                    </Flex>
                </Flex>
                <Flex display={'flex'} direction={'column'} justifyContent={'flex-end'} alignItems={'flex-end'} alignSelf={'stretch'}>
                    <Text textAlign={'left'} wordBreak={'break-word'} color="gray.600" fontSize="12px" fontStyle="normal" fontWeight="400" lineHeight="16px">
                        {removePTags(podcast.summary)}
                    </Text>
                </Flex>
            </Flex>
            <Flex p="16px" flexDirection="column" alignSelf="stretch" alignItems="flex-start" justifyContent="flex-start" gap="36px" borderRadius="12px" border="1px" borderColor="gray.300">
                <Flex w={'full'} direction={'row'} alignItems="center" justifyContent="space-between">
                    <Text wordBreak={'break-all'} color="gray.800" fontSize="16px" fontStyle="normal" fontWeight="600" lineHeight="24px">
                        OBJECTIVES
                    </Text>
                </Flex>
                <Flex w={'full'} direction={'row'} alignItems="flex-start" justifyContent="flex-start" gap="12px">
                    <ObjectivesComponent icon={<SearchLogo/>} description={'Proofread, Edit Transcript & Label Speakers.'}/>
                    <ObjectivesComponent icon={<EditTranscriptLogo/>} description={'Highlight Ads.'}/>
                    <ObjectivesComponent icon={<EditTranscriptLogo/>} description={'Highlight Inaudible Content.'}/>
                </Flex>
            </Flex>
            <Flex p="16px" flexDirection="column" alignSelf="stretch" alignItems="flex-start" justifyContent="flex-start" gap="px" borderRadius="12px" border="1px" borderColor="gray.300">
                <Flex w={'full'} direction={'row'} alignItems="center" justifyContent="space-between">
                    <Text wordBreak={'break-all'} color="gray.800" fontSize="16px" fontStyle="normal" fontWeight="600" lineHeight="24px">
                        GUIDELINES
                    </Text>
                </Flex>
                <a
                    href="https://braincap.notion.site/Transcription-Guidelines-62b3b99f52a94925ab5e4574963c4f19"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                        textDecorationLine: 'underline',
                        color: 'black',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '20px',
                        cursor: 'pointer'
                    }}
                >
                    Click here for Guidelines
                </a>

            </Flex>
        </Flex>
    )
}
