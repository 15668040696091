import {Button, Flex, FormControl, FormErrorMessage, Input, InputGroup, InputRightElement, Spinner, Text} from "@chakra-ui/react";
import {GoogleAuthProvider, signInWithPopup,} from "firebase/auth";
import {ViewOffIcon} from "@chakra-ui/icons";
import React, {useState} from "react";
import {AppLogo} from "../../assets/AppLogo";
import {browserLocalPersistence, setPersistence, signInWithEmailAndPassword} from "firebase/auth";
import {dbAuth} from "../../utils/firebase";
import {StorageUtils} from "../../utils/storage-utils";
import {useAuth} from "../../context/AuthContext";
import {Link} from "react-router-dom";
import {FcGoogle} from "react-icons/fc";

export default function Login () {
    const auth: any = useAuth()
    const [email, setEmail] = React.useState('')
    const [password, setPassword] = React.useState('')
    const [showPassword, setShowPassword] = React.useState(false)
    const [loading, setLoading] = useState(false);
    const [isEmailError, setIsEmailError] = React.useState(false)
    const [isPasswordError, setIsPasswordError] = React.useState(false)

    const signInUser = () => {
        if (isEmailError || isPasswordError) {
            return;
        }
        setLoading(true);
        setPersistence(dbAuth, browserLocalPersistence)
            .then(async () => {
                await signInWithEmailAndPassword(dbAuth, email, password).then(async userRecord => {
                    let token: any = await userRecord?.user.refreshToken;
                    console.log(`token: ${token}`);
                    await dbAuth.currentUser?.getIdTokenResult().then((idToken) => {
                        console.log(idToken.claims);
                    });
                    StorageUtils.saveLocaleStorageToken(token);
                    setLoading(false);
                    window.location.href = "/";
                }).catch(e => {
                    console.log(e);
                    console.log(e.code);
                    console.log(e.message);
                    setLoading(false);
                });
            })
            .catch((error) => {
                // Handle Errors here.
                console.log(error);
                const errorCode = error.code;
                const errorMessage = error.message;
            });
    };

    const provider = new GoogleAuthProvider();

    const signInWithGoogle = async () => {
        try {
            const result = await signInWithPopup(dbAuth, provider);
            const token = result.user.refreshToken;
            await result.user.getIdTokenResult().then((idToken) => {
                console.log(idToken.claims);
            });
            StorageUtils.saveLocaleStorageToken(token);
            window.location.href = "/";
        } catch (error: any) {
            console.log(error.message);
        }
    };

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            signInUser();
        }
    }

    return (
        <Flex direction="column" align="center" justify="center" minHeight="100vh" backgroundColor="white">
            <Flex align="center" justify="start" position="absolute" top="0" left="0" width="1280px" height="100px" paddingLeft="8" paddingRight="1117px" paddingTop="8" paddingBottom="6">
                <Flex alignItems="center" justify="center" gap="2" paddingRight="3">
                    <AppLogo/>
                    <Text color="teal.500" fontSize="20px" fontWeight="medium">Braincap</Text>
                </Flex>
            </Flex>
            <Flex direction="column" align="center" justify="center" backgroundColor="white" borderRadius="12px" border="1px" borderColor={'gray.300'}>
                <Flex direction={'column'} w={'full'} h={'full'} justifyContent={'center'} alignItems={'center'} p={'24px'} gap={'36px'}>
                    <Text fontSize="24px" fontWeight="semibold" marginBottom="6">Sign in</Text>
                    <Flex direction={'column'} alignItems={'center'} gap={'24px'}>
                        <FormControl isRequired id={'email'} isInvalid={isEmailError}>
                            <Input w={'full'} value={email} onChange={(e) => {setEmail(e.target.value)}} placeholder="Email*" _placeholder={{color: 'blackAlpha.500'}} size="md" borderRadius="md" padding="2" width="487px" />
                            <FormErrorMessage>Invalid email</FormErrorMessage>
                        </FormControl>
                        <FormControl isRequired id={'password'} isInvalid={isPasswordError}>
                            <InputGroup size="md" width="487px">
                                <Input w={'full'} type={showPassword ? "text" : "password"} value={password} onChange={(e) => {setPassword(e.target.value)}} onKeyDown={handleKeyDown} placeholder="Password*" _placeholder={{color: 'blackAlpha.500'}} borderRadius="md" padding="2" />
                                <InputRightElement width="4.5rem">
                                    <ViewOffIcon onClick={() => {showPassword? setShowPassword(false) : setShowPassword(true)}} color={'gray.500'} />
                                </InputRightElement>
                            </InputGroup>
                            <FormErrorMessage>Invalid password</FormErrorMessage>
                        </FormControl>
                        <Text align={'center'} color="teal.500" fontSize="14px" fontWeight="semibold">Forget Password?</Text>
                        <Button disabled={loading} w={'full'} onClick={signInUser} variant={'solid'} borderRadius="md" paddingY="2.5" width="487px" fontSize="16px">
                            {loading ? <Spinner size={'md'} color={'white'}/> : 'Sign in'}
                        </Button>
                        <Button w="full" isDisabled={true} onClick={signInWithGoogle} variant="googleSignIn" borderRadius="md" py="2.5" width="487px" fontSize="16px" leftIcon={<FcGoogle/>}>
                            Sign in with Google
                        </Button>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    )
}
