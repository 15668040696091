import {Flex, TabIndicator, TabList, TabPanel, TabPanels, Tabs, Text, useDisclosure} from "@chakra-ui/react";
import {ChevronLeftIcon} from "@chakra-ui/icons";
import React, {useEffect, useState} from "react";
import {PodcastEpisode} from "../../models/PodcastEpisode";
import {Link, useParams} from "react-router-dom";
import {Tab} from "../../components/ui-utils";
import {BraincapTask, TranscriptionState} from "../../models/Localization";
import {ENDPOINTS, useApi} from "../../api/braincap-api";
import {useUser} from "../../context/UserContext";
import {BacklogModule} from "../../components/tasks/task-list";
import {useWorkspace} from "../../context/WorkspaceContext";

export default function Dashboard() {
    const user: any = useUser()
    const userId = user.userId
    const token = user.token
    const workspace: any = useWorkspace()
    const [tasks, setTasks] = useState<BraincapTask[]>([])
    const [onGoingTasks, setOnGoingTasks] = useState<BraincapTask[]>([])
    const [assignedTasks, setAssignedTasks] = useState<BraincapTask[]>([])
    const [loading, setLoading] = useState<boolean>(true)
    const [tabIndex, setTabIndex] = useState<number>(0)
    const { podcastId }: any = useParams();

    const sessionsReq = useApi(ENDPOINTS.GET_TRANSCRIPTION_SESSION, token, false)

    useEffect(() => {
        if (workspace.tasks.length > 0) {
            let notStartedTasks: BraincapTask[] = []
            let activeTasks: BraincapTask[] = []
            workspace.tasks.forEach((task: BraincapTask) => {
                //check if task.episode.title contains 'GUEST SERIES'
                if (user.user.ongoing_sessions && user.user.ongoing_sessions.includes(task.id)) {
                    activeTasks.push(task)
                }
                else {
                    notStartedTasks.push(task)
                }
            })
            setTasks(notStartedTasks)
            setOnGoingTasks(activeTasks)
            setLoading(false)
        } else if (!user.loading && !workspace.loading) {
            sessionsReq.setPayloadData({
                labeler: user.user
            })
        }
    }, [user.loading, workspace.loading])

    useEffect(() => {
        if (!sessionsReq.loading && sessionsReq.data) {
            let notStartedTasks: BraincapTask[] = []
            let activeTasks: BraincapTask[] = []
            sessionsReq.data.forEach((task: any) => {
                if (user.user.ongoing_sessions && user.user.ongoing_sessions.includes(task.id)) {
                    activeTasks.push(task)
                } else if (user.user.assigned_sessions && user.user.assigned_sessions.includes(task.id)) {
                    assignedTasks.push(task)
                }
                else {
                    notStartedTasks.push(task)
                }
            })
            setTasks(notStartedTasks)
            setOnGoingTasks(activeTasks)
            workspace.setTaskDownloaded(true)
            setLoading(false)
        }
    }, [sessionsReq.loading])
    const handleTabsChange = (index: number) => {
        setTabIndex(index)
    }

    return (
        <Flex w="full" flexDirection="column" alignItems={'flex-start'}>
            <Flex direction="row" p={'6px'} w={'full'}>
                <Link to={'/'}>
                    <ChevronLeftIcon  boxSize={'36px'}/>
                </Link>
            </Flex>
            <Tabs w={'full'} position={'relative'} _selected={{textColor:'teal.500'}} alignItems={'flex-start'} isLazy={true} index={tabIndex} onChange={handleTabsChange} size={'lg'} variant={'unstyled'}>
                <TabList w={'full'}>
                    <Tab>
                        <Text>
                            {`New (${tasks.length})`}
                        </Text>
                    </Tab>
                    <Tab>
                        <Text>
                            {`Ongoing (${onGoingTasks.length})`}
                        </Text>
                    </Tab>
                </TabList>
                <TabIndicator
                    height="2px"
                    bg="teal.500"
                    alignSelf={'stretch'}
                />
                <TabPanels w={'full'} h={'full'} pt={'24px'}>
                    <TabPanel p={'0px'}>
                        <BacklogModule tasks={tasks as  BraincapTask[]} loading={loading}/>
                    </TabPanel>
                    <TabPanel p={'0px'}>
                        <BacklogModule tasks={onGoingTasks as  BraincapTask[]} loading={loading}/>
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Flex>
    )
}
