import {Flex} from "@chakra-ui/react";
import React, {useEffect} from "react";
import NavigationBar from "../components/navigation-bar";
import WorkspaceProvider from "../context/WorkspaceContext";

interface Page {
    name: string
    children: React.ReactNode | React.ReactNode[],
}

export default function LabelerLayout({name, children}: Page) {
    return (
        <WorkspaceProvider>
            <Flex minH={'100vh'} direction={'column'} justifyContent={'center'} bgColor={'gray.50'}>
                {/* Navbar with position sticky */}
                <Flex w={'full'} px={'5%'} justifyContent="center" alignItems="center" bg="white" position="sticky" top="0" zIndex="1000">
                    <NavigationBar page={name}/>
                </Flex>
                <Flex w={'full'} minH={'100vh'} px={'5%'} justifyContent="center">
                    {children}
                </Flex>
            </Flex>
        </WorkspaceProvider>
    )
}
