import {User} from "./Users";
import {Language} from "./AppConfig";
import {PodcastLocalizationMeta} from "./Podcast";

export interface Labeler extends User {
    is_active: boolean
    working_language: Language,
    type: LabelerType
    teams: string[],
    ongoing_sessions: string[],
    completed_sessions: string[],
    assigned_sessions: string[],
}

export type LabelerMeta = Pick<Labeler, 'id' | 'name' | 'type' | 'email' | 'working_language' | 'teams'>
export type PodcastLabelerMeta = LabelerMeta & {
    joined_at: any,
    ongoing_sessions?: string[],
    completed_sessions?: string[],
    average_rating?: number,
}
export type LabelerPodcastMeta = PodcastLocalizationMeta & {
    joined_at: any,
    has_accepted_terms: boolean,
    ongoing_sessions?: string[],
    completed_sessions?: string[],
    average_rating?: number,
}

export type LabelerRegistrationForm = Pick<Labeler, 'first_name' | 'last_name' | 'category' | 'type' | 'working_language' | 'phone' | 'email' | 'password'>
export interface Dubber extends Labeler {
    age: number,
    sex: 'male' | 'female',
    is_contracted?: boolean,
    samples?: string[],
    bids?: string[],
}

export type DubberMeta = Pick<Dubber, 'id' | 'name'  | 'age' | 'working_language'>


export enum LabelerType {
    TRANSCRIPTIONIST = "TRANSCRIPTIONIST",
    TRANSCREATOR = "TRANSCREATOR",
    PROOFREADER = "PROOFREADER",
    TRANSLATOR = "TRANSLATOR",
    TRANSLATION_REVIEWER = "TRANSLATION_REVIEWER",
    VOICE_OVER_ACTOR = "VOICE_OVER_ACTOR"
}
