import axios from "axios";
import React, {useEffect} from "react";
import qs from 'qs';

export const ENDPOINTS = {
    USER_VERIFY: '/user/verify',
    USER_INFO: '/user/info',
    USER_SIGNUP: '/user/signup',
    USER_SIGNUP_GOOGLE: '/user/signup/google/',
    GET_TEAMS: '/teams/get',
    JOIN_TEAM: '/labeler/team/join',
    GET_TRANSCRIPTION_SESSION: '/transcription/session/all',
    START_TRANSCRIPTION_SESSION: '/transcription/session/start/',
}

export const apiStates = {
    STARTED: 'STARTED',
    LOADING: 'LOADING',
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
};

interface RequestParameters {
    endpoint: string,
    userToken: string,
    payload: any
}

interface RequestData {
    state: string,
    error: any,
    data: any,
}


export const useApi = (endpoint: string, userToken: string, runRequest?: boolean, payload?: any, params?: any, callbackFn?: any) => {
    const [execute, setExecute] = React.useState(runRequest)
    const [payloadData, setPayloadData] = React.useState(payload? payload : null)

    const [reqParams, setReqParams] = React.useState<RequestParameters>({
        endpoint: "",
        userToken: "",
        payload: payloadData,
    })
    const [data, setData] = React.useState<RequestData>({
        state: apiStates.STARTED,
        error: '',
        data: null,
    });

    useEffect(() => {
        if (payloadData) {
            setExecute(true)
        }
    }, [payloadData])

    const updateData = (partialData: object) => setData({ ...data, ...partialData });
    const updateParams = (partialParams: object) => setReqParams({ ...reqParams, ...partialParams });

    React.useEffect(() => {
        if (endpoint !== reqParams.endpoint
            || userToken !== reqParams.userToken
            || JSON.stringify(payloadData) !== JSON.stringify(reqParams.payload)) {
            setReqParams({
                endpoint: endpoint,
                userToken: userToken,
                payload: payloadData
            });
            setData({...data, state:apiStates.STARTED})
        }
    }, [endpoint, userToken, payloadData, reqParams, execute])


    React.useEffect(() => {
        if (reqParams.endpoint && execute && data.state === apiStates.STARTED) {
            updateData({
                state: apiStates.LOADING,
                data: null,
                error: ''
            });

            const request = async () => axios.get(reqParams.endpoint, {
                headers: {'Authorization': `Bearer${reqParams.userToken}`},
                params: params,
                paramsSerializer: (params) => {
                    return qs.stringify(params, { arrayFormat: 'repeat' })
                }
            });
            const requestPost = async () => axios.post(reqParams.endpoint, reqParams.payload, {
                headers: {'Authorization': `Bearer${reqParams.userToken}`},
                params: params,
                paramsSerializer: (params) => {
                    return qs.stringify(params, { arrayFormat: 'repeat' })
                }
            });

            (payloadData ? requestPost : request)()
                .then((response) => {
                    updateData({
                        state: apiStates.SUCCESS,
                        data: response.data,
                        error: null
                    });
                    if (callbackFn) {
                        callbackFn(response.data);
                    }
                    setExecute(false)
                    setPayloadData(null)
                })
                .catch((response) => {
                    /**
                    if (errCallbackFn) {
                        errCallbackFn(response.data);
                    }
                     **/

                    console.log(response.response)

                    updateData({
                        state: apiStates.ERROR,
                        error: response.response,
                        data: null
                    });
                    setExecute(false)
                    setPayloadData(null)
                });
        }
    }, [data.state, execute, reqParams, payloadData, updateData]);

    return {
        loading: data.state === apiStates.LOADING || data.state === apiStates.STARTED,
        error: data.error,
        data: data.data,
        state: data.state,
        endpoint: endpoint,
        updateParams: updateParams,
        setPayloadData: setPayloadData,
        execute: () => {setExecute(true);}
    };
}
