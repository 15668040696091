import {Podcast} from "../../models/Podcast";
import {Flex, useDisclosure, Image, Text, Button} from "@chakra-ui/react";
import React from "react";
import {useUser} from "../../context/UserContext";
import {EpisodesIcon} from "../../assets/EpisodesIcon";
import {Link, useNavigate} from "react-router-dom";
import ShowDashboardPanel from "./show-dashboard-panel";
import {BraincapSettingsIcon} from "../../assets/BraincapSettingsIcon";

export const ShowCard = ({podcast}: {podcast: Podcast}) => {
    const user: any = useUser()
    const isPartOfTeam = user.user.teams.length > 0 && user.user.teams.includes(podcast.id as string)
    const navigate: any = useNavigate()


    const { isOpen, onOpen, onClose } = useDisclosure()
    return (
        <Flex w={'fit-content'} p="12px" flexDirection="column" alignItems="center" justifyContent={'center'} bgColor={'#fff'} borderRadius="12px" gap={'8px'} boxShadow={'sm'}>
            <Flex>
                <Image fallbackSrc='https://via.placeholder.com/150' src={podcast.image_url} boxSize={'220px'} borderRadius="8px" bgColor="gray.100"/>
            </Flex>
            <Flex wordBreak={'break-word'} overflow='hidden' textOverflow='ellipsis' w={'full'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <Text noOfLines={1} w={'220px'} color="gray.900" fontSize="16px" fontWeight="600" lineHeight="24px">{podcast.title}</Text>
            </Flex>
            <Flex w={'full'} flexDirection={'row'} alignItems={'center'} gap={'8px'}>
                <EpisodesIcon/>
                <Text color="gray.600" fontSize="14px" fontWeight="400" lineHeight="20px">{podcast.episodes.length} Episodes</Text>
            </Flex>
            {!isPartOfTeam?
                <Button w={'full'} onClick={onOpen} variant={'solid'}>Join Team</Button>
                :
                <Flex w={'full'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                    <Button onClick={() => {navigate('/dashboard')}} variant={'outline'}>Go to Dashboard</Button>
                    <Flex onClick={onOpen}>
                        <BraincapSettingsIcon/>
                    </Flex>
                </Flex>

            }
            <ShowDashboardPanel podcast={podcast} isOpen={isOpen} onOpen={onOpen} onClose={onClose}/>
        </Flex>
    )
}
