import {PodcastEpisode} from "../../models/PodcastEpisode";
import React, {useEffect, useState} from "react";
import {
    Box,
    Button,
    Center,
    Checkbox,
    Flex,
    Grid,
    Slide,
    Text,
    useBreakpointValue,
    useDisclosure
} from "@chakra-ui/react";
import {SearchLogo} from "../../assets/SearchIcon";
import {CustomPagination} from "../custom-pagination";
import {ShowCardSkeleton} from "../skeletons";
import {Podcast} from "../../models/Podcast";
import {useUser} from "../../context/UserContext";
import {TaskCard} from "./task-card";
import {BraincapTask} from "../../models/Localization";
import {ENDPOINTS, useApi} from "../../api/braincap-api";

export const BacklogModule = ({tasks, loading}: {tasks: BraincapTask[], loading: boolean, isDisabled?: boolean}) => {
    const user: any = useUser()
    const userId = user.userId
    const token = user.token
    const [searchTerm, setSearchTerm] = useState('');
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [page, setPage] = useState(1); // initialize the current page to 1
    const [inConfigurationSize, setInConfigurationSize] = useState<number>(0)
    const [checkAll, setCheckAll] = useState<boolean>(false)

    const dynamicPageSize = useBreakpointValue({ base: 12, md: 12, lg: 12 , xl: 8 });
    const [pageSize, setPageSize] = useState(dynamicPageSize || 8);

    useEffect(() => {
        setPageSize(dynamicPageSize || 8);
    }, [dynamicPageSize]);
    const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
        setPage(1)
    };
    const handleClearSearch = () => {
        setSearchTerm('');
    };

    // calculate the start and end indices of the episodes to display on the current page
    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;

    const filteredTasks = tasks.filter((task: BraincapTask) => {
        const { title } = task.episode ;
        const normalizedSearchTerm = searchTerm.toLowerCase();
        return (
            title.toLowerCase().includes(normalizedSearchTerm)
        );
    });


    return (
        <Flex w={'full'} flexDirection={'column'} alignItems={'flex-start'} gap={'24px'}>
            <Flex w={'full'} direction={'row'} alignItems={'center'}>
                <Flex w={ '85%'} justifyContent={'flex-start'} alignItems={'center'} direction={'row'}>
                    <SearchLogo onType={handleSearchInputChange} onOpenWidth={'full'} />
                </Flex>
                <Flex w={'full'} justifyContent={'space-between'} alignItems={'center'} direction={'row'}>
                    <Flex justifyContent={'flex-start'} alignItems={'flex-start'} direction={'row'} gap={'12px'}>
                        <CustomPagination
                            totalPages={Math.ceil(filteredTasks.length / pageSize)}
                            currentPage={page}
                            onPageChange={setPage}
                        />
                    </Flex>
                    <>

                    </>
                </Flex>
            </Flex>
            <Flex w="full" flexDirection="column" alignItems={'flex-start'}>
                {!loading ?
                    <Grid w={'full'} templateColumns='repeat(4, 1fr)' gap={'24px'}>
                        {filteredTasks.slice(startIndex, endIndex).map((task: BraincapTask) => (
                            <TaskCard key={task.id} task={task}/>
                        ))}
                    </Grid>
                    :
                    <Grid w={'full'} templateColumns='repeat(4, 1fr)' gap={'24px'}>
                        {[...Array(2)].map((_, index) => (
                            <ShowCardSkeleton key={index}/>
                        ))}
                    </Grid>
                }
            </Flex>
        </Flex>
    )
}
