export const removePTags = (input: string): string => {
    return input.replace(/<\/?p>/g, '');
}

export const secondsToHHMMSS = (seconds: number): string => {
    // Calculate the number of hours, minutes, and seconds
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;

    // Format the values as two-digit strings with leading zeros, if needed
    const hoursStr = hours.toString().padStart(2, '0');
    const minutesStr = minutes.toString().padStart(2, '0');
    //don't display decimals for seconds
    const secsStr = secs.toFixed(0).padStart(2, '0');


    // Return the formatted time string
    return `${hoursStr}:${minutesStr}:${secsStr}`;
}

export const termsOfService = `SERVICE CONTRACT\n

 1. IDENTIFICATION OF PARTIES
 This service contract (hereinafter the "Contract") is made and entered into as of this day by and between :

BRAINCAP a Legal Entity duly organized and existing under the laws of the United States of America and the State of California, with its principal place of business located at [Addresse] (hereinafter the "Company"), and

the Worker, an independent freelancer operating on its own, (hereinafter the "Worker").

Collectively the Company and the Worker shall be referred to herein as the "Parties."

The Company and the Worker, individually and collectively, hereby acknowledge that they have read and understood the terms and conditions of this Contract and voluntarily enter into this contractual relationship to define their respective rights, obligations, and expectations pertaining to the services to be provided by the Worker to the Company.

Both Parties affirm their legal capacity to enter into this Contract and agree to be bound by its provisions throughout the duration of their engagement.

 2. SCOPE OF SERVICES

The Worker agrees to provide transcription, translation, or voice-over services (hereinafter referred to as the "Services") to the Company for audio content through the Company's designated software platform. The Company shall assign specific tasks, which may include transcription, translation, or providing voice-over for audio content, to the Worker."


3. EFFECTIVE DATE & DURATION OF CONTRACT

3.1 This Contract shall commence on the date of its signature by both Parties and shall remain in effect until the completion of the services, or until the satisfactory fulfillment of all agreed-upon obligations and deliverables by the Worker.

3.2 Upon completion of the services, both Parties shall conduct a final review and acceptance of the deliverables, and the Contract shall automatically terminate. However, if any additional services or tasks are requested by the Company and agreed upon by the Worker, this Contract shall be extended to encompass the duration necessary for the provision of these additional services.

3.3 In the event of early termination, either party may terminate this Contract by providing written notice to the other party, specifying the effective date of termination. The Worker shall be compensated for all services satisfactorily provided up to the termination date.

3.4 Both Parties agree that the duration of this Contract is contingent upon the Worker’s timely and satisfactory performance, and any delay or breach of obligations that significantly impacts the timely completion of the services may result in termination at the discretion of the Company.


4. SCHEDULE

4.1 TRANSCRIPTION

4.1.1 The Worker agrees to provide a minimum of 45 minutes of transcription services per day, Monday through Friday, during the term of this Contract. The specific schedule for the provision of these services shall be mutually agreed upon by both Parties.

4.1.2 The Worker shall exercise reasonable efforts to ensure the daily transcription quota is met consistently. However, the Company acknowledges that certain factors, such as the complexity of the transcription tasks or unforeseen circumstances, may occasionally affect the ability to achieve the full 45-minute requirement on certain days.

4.1.3 In such cases where the Worker anticipates potential delays or disruptions to the agreed-upon schedule, they shall promptly inform the Company and work collaboratively to address any scheduling conflicts or adjust the workload accordingly.

4.1.4 The Parties understand that adherence to the specified transcription duration is vital to the timely completion of the project and the satisfaction of the Company. As such, both Parties shall maintain open communication and cooperate to ensure that the transcription services are provided efficiently and without undue delay.

4.1.5 It is further understood that any unused portion of the 45-minute daily transcription requirement cannot be carried over to subsequent days and does not entitle the Worker to any additional compensation or extension of the Contract.


4.2 TRANSLATION

4.2.1 The Worker agrees to provide a minimum of 45 minutes of translation services per day, Monday through Friday, during the term of this Contract. The specific schedule for the provision of these services shall be mutually agreed upon by both Parties.

4.2.2 The Worker shall exercise reasonable efforts to ensure the daily translation quota is met consistently. However, the Company acknowledges that certain factors, such as the complexity of the translation tasks or unforeseen circumstances, may occasionally affect the ability to achieve the full 45-minute requirement on certain days.

4.2.3 In such cases where the Worker anticipates potential delays or disruptions to the agreed-upon schedule, they shall promptly inform the Company and work collaboratively to address any scheduling conflicts or adjust the workload accordingly.

4.2.4 The Parties understand that adherence to the specified translation duration is vital to the timely completion of the project and the satisfaction of the Company. As such, both Parties shall maintain open communication and cooperate to ensure that the translation services are provided efficiently and without undue delay.

4.2.5 It is further understood that any unused portion of the 45-minute daily translation requirement cannot be carried over to subsequent days and does not entitle the Worker to any additional compensation or extension of the Contract.


4.3 VOICE-OVER RECORDINGS

4.3.1 The Worker agrees to provide a minimum of 45 minutes of voice-over recording services per day, Monday through Friday, during the term of this Contract. The specific schedule for the provision of these services shall be mutually agreed upon by both Parties.

4.3.2 The Worker shall exercise reasonable efforts to ensure the daily voice-over recording quota is met consistently. However, the Company acknowledges that certain factors, such as the complexity of the recording tasks or unforeseen circumstances, may occasionally affect the ability to achieve the full 45-minute requirement on certain days.

4.3.3 In such cases where the Worker anticipates potential delays or disruptions to the agreed-upon schedule, they shall promptly inform the Company and work collaboratively to address any scheduling conflicts or adjust the workload accordingly.

4.3.4 The Parties understand that adherence to the specified voice-over recording duration is vital to the timely completion of the project and the satisfaction of the Company. As such, both Parties shall maintain open communication and cooperate to ensure that the voice-over recording services are provided efficiently and without undue delay.

4.3.5 It is further understood that any unused portion of the 45-minute daily voice-over recording requirement cannot be carried over to subsequent days and does not entitle the Worker to any additional compensation or extension of the Contract.


5. CONFIDENTIALITY

5.1 The Worker acknowledges and agrees that all content provided by the Company is strictly confidential and proprietary information of the Company and/or its clients.

5.2 The Worker shall maintain strict confidentiality with respect to all content, materials, or information they may have access to while providing the Services. The Worker shall not disclose, share, or use any of the confidential information for any purpose other than fulfilling the obligations under this Contract, except as otherwise expressly permitted in this Contract.

5.3 The Worker shall not reproduce, record, distribute, or otherwise make available any of the content provided by the Company to any third parties without prior written consent from the Company.

5.4 The Worker shall not use any confidential Information for any purpose other than as expressly permitted by this Contract, and shall not directly or indirectly use such information for its own benefit or the benefit of any third party.

5.5 The Worker must take all reasonable measures to protect the confidentiality of the content, including but not limited to using secure password protection, not leaving any content unattended, and not sharing access credentials with unauthorized individuals.

5.6 Upon termination or expiration of this Contract, or at any time upon the request of the Company, the Worker shall promptly return or destroy all confidential information in its possession, custody, or control, including any copies or reproductions thereof, and provide written certification of such return or destruction upon request.

5.7 The Worker acknowledges that any unauthorized use or disclosure of confidential information may cause irreparable harm to the Company, and therefore, the Company shall be entitled to seek injunctive relief, in addition to any other remedies available at law or in equity.

5.8 The obligations of confidentiality set forth in this clause shall survive the termination or expiration of this Contract and shall continue in effect for a period of five (5) years from the date of disclosure.

5.9 This confidentiality clause shall be governed by and construed in accordance with the laws of the United States of America and the State of California.


6. INTELLECTUAL PROPERTY

6.1 OWNERSHIP OF TRANSCRIPTIONS, TRANSLATIONS, AND VOICE-OVER RECORDINGS

6.1.1. All intellectual property rights, including but not limited to patents, copyrights, trademarks, trade secrets, transcriptions, translations, voice-over recordings of audio content and any other proprietary rights, developed or created by the Worker during the course of providing services under this Contract, shall be the sole and exclusive property of the Company.

6.1.2 The Worker agrees to promptly and fully disclose all intellectual property created or developed under this Contract to the Company and to take all necessary steps, including executing further documents, as reasonably requested by the Company, to perfect and transfer ownership of such intellectual property to the Company.

6.1.3 The Company reserves the right to modify, edit, or delete any transcriptions, translations, or voice-over recordings, at its sole discretion, without requiring the Workers’ consent.


6.2 CONFIDENTIALITY AND TRADE SECRETS

6.2.1 The Worker shall treat all information received from the Company as confidential and shall not disclose, use, or reproduce such information for any purpose other than performing the services under this Contract.

6.2.2. The Worker shall take all reasonable measures to protect the Company's confidential information and trade secrets from unauthorized disclosure or use, both during and after the term of this Contract.


6.3 THIRD-PARTY INTELLECTUAL PROPERTY

6.3.1 The Worker warrants that its services and deliverables under this Contract will not infringe upon or violate the intellectual property rights of any third party. The Worker agrees to indemnify and hold harmless the Company against any claims, damages, or liabilities arising from any such infringement or violation.


6.4 RETURN OF COMPANY PROPERTY

6.4.1 Upon termination of this Contract, The Worker shall promptly return to the Company all company property, including but not limited to documents, data, equipment, and other materials containing or pertaining to the Company's intellectual property.


6.5 SURVIVAL OF OBLIGATIONS

6.5.1 The obligations of confidentiality, intellectual property ownership, and non-disclosure shall survive the termination or expiration of this Contract.


6.6 DATA PROTECTION

6.6.1 The Worker shall handle any personal data or sensitive information obtained during the provision of the Services in strict compliance with applicable data protection laws and regulations. The Worker shall implement appropriate security measures to protect such data from unauthorized access, disclosure, or use and shall not use the data for any purposes other than fulfilling the obligations under this Contract.


6.7 GOVERNING LAW FOR INTELLECTUAL PROPERTY

6.7.1 Any disputes or claims related to the ownership, infringement, or validity of intellectual property rights developed or created by the Worker under this Contract shall be governed by and construed in accordance with the laws of the United States of America and the state of California.

 7. PAYMENT & PAYMENT TERMS

7.1 The Worker shall be compensated by the Company on a weekly basis, based on the agreed-upon payment terms for completed and accepted transcriptions, translations, or voice-over recordings.

7.2 The payment terms, including the rate and method of payment, will be provided to the Worker separately and are subject to mutual agreement.

7.3 The Worker agrees not to engage in any activities or provide services that directly compete with the Services provided to the Company under this Contract for a period of one (1) year from the date of termination or expiration of this Contract. Additionally, the Worker shall not solicit or attempt to solicit any clients, customers, or employees of the Company for one (1) year after the termination or expiration of this Contract.

7.4 The Company shall not be obligated to pay the Worker who has not completed the assigned job before the provided deadline. Timely completion of the Services is a fundamental requirement of this Contract.

 8. TERMINATION

8.1 Either party may terminate this Contract at any time and for any reason, without cause, upon providing written notice to the other party at least five (5) days prior to the intended termination date.

8.2 Upon termination of this Contract, the Worker shall immediately return or destroy all copies of confidential information in their possession and cease any use of the Company's proprietary information.

8.3 The Company reserves the right to terminate this Contract for cause if the Worker breaches any material term of this Contract, including but not limited to breach of confidentiality, violation of intellectual property rights, or failure to meet the agreed-upon quality standards for the Services.

 9. INDEPENDENT CONTRACTOR

9.1 The Worker acknowledges and agrees that they are independent contractors and not employees, agents, or partners of the Company.
 9.2 The Worker shall be solely responsible for their own taxes, insurance, and any other statutory obligations related to their status as independent contractors.


10. ASSIGNEMENT OF CONTRACT 
 10.1 The Parties hereby acknowledge and agree that this Contract is personal to them, and no party shall have the right to assign, transfer, or delegate any of its rights, duties, or obligations under this Contract to any third party without the express written consent of the other party.

10.2 Any attempt to assign or transfer this Contract without such written consent shall be deemed null and void, and such assignment or transfer shall not be legally recognized or enforceable.

10.3 This non-assignment clause shall be binding upon and inure to the benefit of the Parties to this Contract and their respective successors, heirs, and permitted assigns.

 11. GOVERNING LAW AND DISPUTE RESOLUTION

11.1 This Contract shall be governed by and construed in accordance with the laws of the United States of America and the State of California, without regard to its conflict of law principles.

11.2 In the event of any dispute arising out of or relating to this Contract, the Parties agree to first attempt to resolve the dispute amicably through good-faith negotiations. Either party may initiate the dispute resolution process by providing written notice to the other party, clearly stating the nature of the dispute and their proposed resolution.

11.3 If the dispute remains unresolved within two (2) business days from the date of the written notice, the Parties agree to engage in mediation. The mediation shall be conducted by a neutral and impartial mediator agreed upon by both Parties. The mediator’s fees and expenses shall be shared equally between Parties, unless otherwise agreed.

11.4 If the mediation process does not lead to a resolution within four (4) days from the commencement of mediation, or if either party refuses to participate in mediation, the Parties agree to submit the dispute to binding arbitration. The arbitration shall be conducted in accordance with the rules of [Arbitration Institution Name], and the award rendered by the arbitrator(s) shall be final and binding.

11.5 The Parties agree that any dispute resolution proceedings shall be conducted confidentially and that the existence, content, and outcome of the proceedings shall be treated as confidential by both Parties, except as necessary to enforce any decision onward.

11.6 This dispute resolution clause shall survive the termination or expiration of the Contract.

11.7 Any disputes arising out of or in connection with this Contract shall be subject to the exclusive jurisdiction of the courts located in the United States of America and the state of California, and the Parties hereby submit to the personal jurisdiction of such courts.


12. FORCE MAJEURE

12.1 Neither Party shall be liable for any failure or delay in the performance of their obligations under this Contract due to circumstances beyond their reasonable control, including but not limited to acts of God, natural disasters, fires, strikes, labor disputes, war, or government orders (hereinafter referred to as "Force Majeure Event"). In the event of a Force Majeure Event, the Parties shall take reasonable steps to mitigate the impact on the performance of this Contract.


13. ENTIRE CONTRACT

13.1 This Contract constitutes the entire understanding and agreement between the Parties concerning the subject matter herein and supersedes all prior and contemporaneous agreements, whether oral or written.

13.2 Any changes or modifications to this Contract must be made in writing and signed by both Parties to be effective.


14. ACCEPTANCE OF CONTRACT TERMS

14.1 By checking this box, I, the undersigned Worker, hereby acknowledge that I have read and understood all the terms and conditions outlined in this Contract. I agree to be bound by the provisions set forth in this document and undertake to fulfill all my obligations and responsibilities as specified.

14.2 I affirm that I have had the opportunity to seek independent legal advice if I so desired and that my acceptance of this Contract is voluntary and without any undue influence or coercion.

14.3 I understand that my compliance with the terms of this Contract is essential to maintaining a productive and professional working relationship with the Company. I further acknowledge that any breach of this Contract may result in termination of services and potential legal consequences.

14.4 By clicking this box, I signify my full acceptance of all the terms and conditions contained within this Contract and confirm my commitment to abide by them throughout the duration of our engagement.

15. SEVERABILITY

15.1 If any provision of this Contract is held to be invalid, illegal, or unenforceable under any applicable law, such provision shall be deemed modified to the extent necessary to make it valid, legal, and enforceable. If modification is not possible, the invalid, illegal, or unenforceable provision shall be deemed severed from this Contract, and the remainder of the Contract shall continue in full force and effect.

16. CHOICE OF LAW AND JURISDICTION

16.1 This Contract shall be governed by and construed in accordance with the laws of the United States of America and the state of California, without regard to its conflict of law principles.

16.2 The Parties agree that any dispute, claim, or controversy arising out of or relating to this Contract, or the breach, termination, enforcement, interpretation, or validity thereof, shall be subject to the exclusive jurisdiction of the courts located in the United States of America and the state of California, and each Party hereby consents to the personal jurisdiction and venue of such courts.

16.3 Notwithstanding the above, if the Worker is an individual who is not a resident of the state of California, the Worker may select the jurisdiction of their residence as the governing law and jurisdiction for disputes arising from this Contract. In such a case, the Worker agrees to provide written notice to the Company specifying the chosen jurisdiction, and the Company shall comply with the applicable laws of that jurisdiction to the extent required.

16.4 Any and all legal actions or proceedings arising out of or relating to this Contract shall be conducted exclusively in the selected jurisdiction, and the Parties waive any objection to such venue or personal jurisdiction.

17. SEEKING INDEPENDENT LEGAL ADVICE

The Parties acknowledge that this Contract contains legally binding obligations and rights. Each Party is encouraged to seek independent legal advice from a qualified attorney before accepting this Contract. By clicking the 'Accept' button, the Worker acknowledges that they have had the opportunity to seek independent legal advice, and their acceptance of this Contract is made with a full understanding of its terms and implications.

The Company recommends that the Worker consult with legal counsel to ensure that they comprehend the legal significance and consequences of this Contract, as well as their rights and obligations under its terms.

It is important to note that the decision to seek independent legal advice is entirely voluntary and at the sole discretion of the Worker. By accepting this Contract, the Worker affirms that they have had the opportunity to do so and voluntarily choose to proceed without coercion or undue influence.

[Checkbox for Acceptance]
`
