import React, {useEffect, useState} from "react";
import {Flex, Text, useDisclosure, MenuList, Menu, MenuButton, MenuItem} from "@chakra-ui/react";
import { FaRegUserCircle } from "react-icons/fa";
import {useAuth} from "../context/AuthContext";
import {useWorkspace} from "../context/WorkspaceContext";

export default function NavigationBar({ page }: { page: string }) {
    const workspace: any = useWorkspace()
    const { isOpen, onOpen, onClose } = useDisclosure();
    const auth: any = useAuth();
    const [overrideNavBarEpisodeTitle, setOverrideNavBarEpisodeTitle] = useState<boolean>(false)

    useEffect(() => {
        if (workspace.overrideNavBarEpisodeTitle) {
            setOverrideNavBarEpisodeTitle(true)
        }
    }, [workspace.overrideNavBarEpisodeTitle])
    return (
        <Flex w={"full"} alignItems={"center"} justifyContent={"space-between"}>
            <Flex w={overrideNavBarEpisodeTitle? "50%" : "85%"} p="8px 0px" alignItems="center">
                <Flex display="flex" p="8px 12px" justifyContent="center" alignItems="center" gap="8px">
                    <Text color="teal.500" fontSize="20px" fontWeight="medium">
                        Braincap
                    </Text>
                </Flex>
                <Flex p="2px 8px" justifyContent="center" alignItems="center" border={"1px"} borderRadius="12px" borderColor="teal.400" bg="white">
                    <Text color="teal.400" textAlign="center" fontSize="10px" fontWeight="400" lineHeight="16px">
                        Beta
                    </Text>
                </Flex>
            </Flex>
            <Flex direction={"row"} alignItems={"center"} justifyContent={"space-between"} w={"full"}>
                <Flex justifyContent={"center"} alignItems={"center"}>
                    <Text color="gray.700" textAlign="center" fontSize="20px" fontWeight="500" lineHeight="28px">
                        {overrideNavBarEpisodeTitle ? workspace.navBarEpisodeTitle : page}
                    </Text>
                </Flex>
                <Flex p="8px 0px" alignItems="center" gap={"28px"} justifyContent={"flex-end"}>
                    <Menu>
                        <MenuButton>
                            <FaRegUserCircle color={"teal"} />
                        </MenuButton>
                        <MenuList>
                            <MenuItem onClick={() => {auth.logout()}}>
                                Logout
                            </MenuItem>
                        </MenuList>
                    </Menu>
                </Flex>
            </Flex>
        </Flex>
    );
}
