import React, {createContext, useContext, useEffect, useState} from "react";
import {ENDPOINTS, useApi} from "../api/braincap-api";
import {useAuth} from "./AuthContext";
import {User} from "../models/Users";
import {Labeler} from "../models/Labeler";

export const UserContext = createContext(null);

export default function UserProvider(props: any) {
    const auth: any = useAuth()
    const token = auth.token
    const [loading, setLoading] = useState(true);
    const [userId, setUserId] = useState(null)
    const [user, setUser] = useState<any>()

    const userDataReq = useApi(ENDPOINTS.USER_INFO, token, false)

    useEffect(() => {
        if (!!token && !userId && !auth.loading) {
            userDataReq.execute()
        }
    }, [token, auth.loading])

    useEffect(() => {
        if (!userDataReq.loading && userDataReq.data) {
            setUserId(userDataReq.data.id)
            setUser(userDataReq.data)
            setLoading(false)
        }

        if (!auth.loading && userDataReq.error) {
            setLoading(false)
        }
    }, [userDataReq.loading])

    return (
        <UserContext.Provider value = {{
            token: token,
            userId: userId,
            user: user,
            setUser: setUser,
            loading: loading,
            userDataReq: userDataReq
        }} {...props} />
    )
}

export const useUser = () => useContext(UserContext)



