import {Flex, Icon} from "@chakra-ui/react";

export const AppLogo = () => {

    return (
        <Flex>
            <Icon width="16px" height="22px" viewBox="0 0 16 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.1261 10C9.57378 10 9.12607 10.4477 9.12607 11C9.12607 11.5523 9.57378 12 10.1261 12V10ZM1 19.7479H0V21.7479H1V19.7479ZM10.1261 12C11.1535 12 12.1389 12.4081 12.8654 13.1346L14.2796 11.7204C13.178 10.6189 11.6839 10 10.1261 10V12ZM12.8654 13.1346C13.5919 13.8612 14 14.8465 14 15.8739H16C16 14.3161 15.3811 12.822 14.2796 11.7204L12.8654 13.1346ZM14 15.8739C14 16.9014 13.5919 17.8867 12.8654 18.6132L14.2796 20.0274C15.3811 18.9259 16 17.4318 16 15.8739H14ZM12.8654 18.6132C12.1388 19.3397 11.1535 19.7479 10.1261 19.7479V21.7479C11.6839 21.7479 13.178 21.129 14.2796 20.0274L12.8654 18.6132ZM10.1261 19.7479H1V21.7479H10.1261V19.7479Z" fill="#38B2AC"/>
                <path d="M1 1.25195H10.1261C11.4187 1.25195 12.6584 1.76546 13.5725 2.67949C14.4865 3.59353 15 4.83323 15 6.12588C15 7.41853 14.4865 8.65823 13.5725 9.57227C12.6584 10.4863 11.4187 10.9998 10.1261 10.9998H1" stroke="#38B2AC" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="square"/>
                <path d="M10.1261 16.874C10.6784 16.874 11.1261 16.4263 11.1261 15.874C11.1261 15.3217 10.6784 14.874 10.1261 14.874V16.874ZM1 14.874H0V16.874H1V14.874ZM10.1261 14.874H1V16.874H10.1261V14.874Z" fill="#38B2AC"/>
                <path d="M10.1261 7.12598C10.6784 7.12598 11.1261 6.67826 11.1261 6.12598C11.1261 5.57369 10.6784 5.12598 10.1261 5.12598V7.12598ZM1 5.12598H0V7.12598H1V5.12598ZM10.1261 5.12598H1V7.12598H10.1261V5.12598Z" fill="#38B2AC"/>
            </Icon>
        </Flex>
    )
}
