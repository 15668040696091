import React, {useEffect, useState} from "react";
import {Flex, IconButton, Text} from "@chakra-ui/react";
import {ChevronLeftIcon, ChevronRightIcon} from "@chakra-ui/icons";

export const ListPagination = ({items, pages, children, direction, node}: {
    direction: 'column' | 'row',
    pages: number,
    items: any,
    children: any,
    node?: any
}) => {
    const [page, setPage] = useState(1); // initialize the current page to 1
    const pageSize = pages; // number of items to display per page
    const totalPages = Math.ceil(items.length / pageSize); // calculate the total number of pages

// calculate the start and end indices of the episodes to display on the current page
    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;

    const handlePrevClick = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

    const handleNextClick = () => {
        if (page < totalPages) {
            setPage(page + 1);
        }
    };

    return (
        <Flex flexDirection={'row'} alignItems={'center'} justifyContent={'center'}>
            <IconButton bgColor={'transparent'} boxSize={8} aria-label={'left'} icon={<ChevronLeftIcon/>} onClick={handlePrevClick}/>
            {items.slice(startIndex, endIndex).map((item: any) => {
                return (
                    children(item)
                )
            })}
            <IconButton bgColor={'transparent'} boxSize={8} aria-label={'right'} icon={<ChevronRightIcon/>} onClick={handleNextClick}/>
        </Flex>
    );
};


export const CustomPagination = ({totalPages, currentPage, onPageChange}: {
    totalPages: number;
    currentPage: number;
    onPageChange: (page: number) => void;
}) => {
    const handlePrevClick = () => {
        if (currentPage > 1) {
            onPageChange(currentPage - 1);
        }
    };

    const handleNextClick = () => {
        if (currentPage < totalPages) {
            onPageChange(currentPage + 1);
        }
    };

    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === "ArrowLeft") {
            handlePrevClick();
        } else if (event.key === "ArrowRight") {
            handleNextClick();
        }
    };

    useEffect(() => {
        window.addEventListener("keydown", handleKeyDown);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [currentPage]);

    return (
        <Flex w={'full'} direction={'row'} alignItems="center" justifyContent="center" gap={'4px'}>
            <ChevronLeftIcon bgColor={"transparent"} alignItems={'center'} justifyContent={'center'} p={'4px'} boxSize={'20px'} color={'black'} onClick={handlePrevClick}/>
            <Text color={'gray.700'} fontSize={'14px'} fontWeight={500} lineHeight={'20px'}>
                {currentPage} of {totalPages}
            </Text>
            <ChevronRightIcon bgColor={"transparent"} alignItems={'center'} justifyContent={'center'} p={'4px'} boxSize={'20px'} color={'black'} onClick={handleNextClick}/>
        </Flex>
    );
};

