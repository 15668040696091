import {getAuth, signInWithPhoneNumber} from 'firebase/auth'
import {initializeApp} from "firebase/app";
import {config} from "./config";

let firebaseApp = initializeApp(config)

export const dbAuth = getAuth(firebaseApp)
export const dbSignInWithPhoneNumber = (phoneNumber: string, appVerifier: any) => {
    return signInWithPhoneNumber(dbAuth, phoneNumber, appVerifier)
}
export const secondsToHHMMSS = (seconds: number): string => {
    // Calculate the number of hours, minutes, and seconds
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;

    // Format the values as two-digit strings with leading zeros, if needed
    const hoursStr = hours.toString().padStart(2, '0');
    const minutesStr = minutes.toString().padStart(2, '0');
    const secsStr = secs.toString().padStart(2, '0');

    // Return the formatted time string
    return `${hoursStr}:${minutesStr}:${secsStr}`;
}

export const millisecondsToHHMMSS = (milliseconds: number): string => {
    // Calculate the number of hours, minutes, and seconds
    const hours = Math.floor(milliseconds / 3600000);
    const minutes = Math.floor((milliseconds % 3600000) / 60000);
    const secs = milliseconds % 60000;

    // Format the values as two-digit strings with leading zeros, if needed
    const hoursStr = hours.toString().padStart(2, '0');
    const minutesStr = minutes.toString().padStart(2, '0');
    //only show seconds
    const secsStr = Math.floor(secs / 1000).toString().padStart(2, '0');
    // Return the formatted time string
    return `${hoursStr}:${minutesStr}:${secsStr}`;
}

export const convertSecondsToMinutes = (seconds: number) => {
    return Math.floor(seconds / 60);
}

export const countWords = (str: string): number => {
    // First, remove any leading or trailing whitespace from the string.
    str = str.trim();

    // Next, split the string into an array of words.
    const words = str.split(/\s+/);

    // Finally, return the length of the array as the number of words.
    return words.length;
}
