import {Drawer, DrawerContent, DrawerOverlay} from "@chakra-ui/modal";
import {
    CloseButton,
    Flex,
    useDisclosure,
    Text,
    Icon,
    Tabs,
    TabList,
    TabIndicator,
    TabPanels,
    TabPanel, Skeleton, FormControl, Checkbox, FormErrorMessage, Slide, Box, Center, Button
} from "@chakra-ui/react";
import {useAuth} from "../../context/AuthContext";
import React, {useEffect, useState} from "react";
import {GrMore} from "react-icons/gr";
import {Tab} from "../ui-utils";
import 'react-circular-progressbar/dist/styles.css';
import {Podcast} from "../../models/Podcast";
import OverviewModule from "./overview-module";
import {termsOfService} from "../../utils/text-utils";
import {useNavigate} from "react-router-dom";
import {ENDPOINTS, useApi} from "../../api/braincap-api";
import {useUser} from "../../context/UserContext";
import {useWorkspace} from "../../context/WorkspaceContext";

export default function ShowDashboardPanel({podcast, isOpen, onOpen, onClose}: {podcast: Podcast, isOpen: boolean, onOpen: any, onClose: any}) {
    const auth: any = useAuth()
    const user: any = useUser()
    const workspace: any = useWorkspace()
    const [tabIndex, setTabIndex] = useState<number>(0)
    const isPartOfTeam = user.user.teams.length > 0 && user.user.teams.includes(podcast.id as string)
    const [termsAccepted, setTermsAccepted] = useState(isPartOfTeam);
    const [termsError, setTermsError] = useState(false);
    const navigate = useNavigate();

    const joinTeamReq = useApi(ENDPOINTS.JOIN_TEAM, user.token, false)

    useEffect(() => {
        if (!joinTeamReq.loading && joinTeamReq.data) {
            workspace.setTasks(joinTeamReq.data.sessions)
            user.setUser(joinTeamReq.data.labeler)
            console.log(joinTeamReq.data)
            navigate('/dashboard')
        }
    }, [joinTeamReq.loading])
    const handleTabsChange = (index: number) => {
        setTabIndex(index)
    }

    return (
        <Drawer isOpen={isOpen} onClose={() => {
            onClose()
            auth.setOpenDrawer(false)
        }}
                size={'xl'}>
            <DrawerOverlay />
            <DrawerContent w={'full'} h={'100vh'}>
                <Flex px={"2%"} h={'full'} py={'2%'} flexDirection="column" alignItems="flex-start" gap="48px" flexShrink={0} boxShadow="-4px 0px 10px 0px rgba(0, 0, 0, 0.06)">
                    <Flex w="full" flexDirection="column" alignItems="flex-start" gap="24px">
                        <Flex w="full" flexDirection="row" alignItems="center" justifyContent="space-between">
                            <CloseButton onClick={onClose} />
                            <Text color="gray.700" textAlign="center" isTruncated fontSize="lg" fontStyle="normal" fontWeight="medium" lineHeight="7">
                                {podcast.title}
                            </Text>
                            <Icon as={GrMore} visibility={'hidden'} />
                        </Flex>
                        <Tabs w={'full'} h={'full'} style={{overflow: 'auto'}} position={'relative'} _selected={{textColor:'teal.500'}} align={'center'} isLazy={true} index={tabIndex} onChange={handleTabsChange} size={'lg'} variant={'unstyled'}>
                            <TabList>
                                <Tab>
                                    <Text>
                                        Overview
                                    </Text>
                                </Tab>
                                <Tab>
                                    <Text>
                                        Contract
                                    </Text>
                                </Tab>
                            </TabList>
                            <TabIndicator pos={'fixed'} height="2px" bg="teal.500"/>
                            <TabPanels w={'full'} h={'full'} mt={'36px'}>
                                <TabPanel p={'0px'}>
                                    <OverviewModule podcast={podcast}/>
                                </TabPanel>
                                <TabPanel p={'0px'} h={'full'}>
                                    <Flex p="16px" style={{overflow: 'auto'}} flexDirection="column" alignSelf="stretch" alignItems="flex-start" justifyContent="flex-start" gap="36px" borderRadius="12px" border="1px" borderColor="gray.300">
                                        <Flex w={'full'} direction={'row'} alignItems="flex-start" justifyContent="space-between">
                                            <Text wordBreak={'break-all'} color="gray.800" fontSize="16px" fontStyle="normal" fontWeight="600" lineHeight="24px">
                                                TERMS AND CONDITIONS AGREEMENT
                                            </Text>
                                        </Flex>
                                        <Flex w={'full'} direction={'column'} alignItems="flex-start" justifyContent="space-between" gap="6px">
                                            <Text style={{ maxHeight: '60vh', overflow: 'scroll', whiteSpace: 'pre-wrap' }} align={'start'} color="gray.900" fontSize="14px" fontStyle="normal" fontWeight="400" lineHeight="24px">
                                                {termsOfService}
                                            </Text>
                                            <Flex flexDirection={'row'} alignItems={'flex-start'} justifyContent={'flex-start'} width="487px">
                                                <Checkbox isDisabled={isPartOfTeam} colorScheme="teal" borderColor="slate.500" marginRight="3" checked={termsAccepted} onChange={(e) => {
                                                    console.log(e.target.checked)
                                                    setTermsAccepted(e.target.checked)
                                                }} />
                                                <Text fontSize="16px">
                                                    I agree with{" "}
                                                    <Text as="span" color="teal.500" fontWeight="semibold">
                                                        Terms,
                                                    </Text>{" "}
                                                    <Text as="span" color="teal.500" fontWeight="semibold">
                                                        Privacy,
                                                    </Text>{" "}
                                                    and{" "}
                                                    <Text as="span" color="teal.500" fontWeight="semibold">
                                                        Conditions
                                                    </Text>
                                                </Text>
                                            </Flex>
                                        </Flex>
                                    </Flex>
                                    <Slide in={true} direction='bottom' style={{ zIndex: 10 }}>
                                        <Box pos={'fixed'} alignItems="center" bottom="0" bg="white" boxShadow={'lg'} h={20} w="100%" zIndex={2}>
                                            <Center>
                                                {!isPartOfTeam?
                                                    <Button onClick={() => {
                                                        if (!termsAccepted) {
                                                            setTermsError(true)
                                                        } else {
                                                            joinTeamReq.setPayloadData({podcast: podcast, labeler: user.user})
                                                        }
                                                    }} isDisabled={!termsAccepted} w={'30%'} variant={'solid'}>
                                                        Join Team
                                                    </Button>
                                                    :
                                                    <Button onClick={() => {navigate('/dashboard')}} w={'30%'} variant={'solid'}>
                                                        Go to Dashboard
                                                    </Button>
                                                }
                                            </Center>
                                        </Box>
                                    </Slide>
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    </Flex>
                </Flex>
            </DrawerContent>
        </Drawer>
    )
}
